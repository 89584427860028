import React from 'react';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href=".">Harrington Managment Consulting LTD</a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Get ready for a change.</h1>
            <p>Website coming soon. Shoot me an email if you're curious.</p>
          </div>
          <a href="mailto:john@harringtonmanagementconsulting.co.uk">
            <div className="cta">Send me an email</div>
          </a>
        </div>
        <div className="footer">
          <span>Copyright &copy; 2023 | Harrington Managment Consulting LTD</span>
        </div>
      </div>
    );
  }
}

export default App;